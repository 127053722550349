@charset "UTF-8";


@import "_setting.scss";
@import "_common2.scss";
@import "_yoyaku.scss";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400&family=Ribeye+Marrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@500&display=swap');