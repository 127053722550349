@charset "UTF-8";
/*! このcssはsass（sass/同名の.scssファイル）からコンパイルされています。修正時はこのcssを直接いじらないようにお願いします */
@import "_setting.scss";


//************************************

// yoyaku

//************************************/
a.booking-timelink {
    display: block;
    pointer-events: none;
    text-decoration: none;
}