@charset "UTF-8";
/*! このcssはsass（sass/同名の.scssファイル）からコンパイルされています。修正時はこのcssを直接いじらないようにお願いします */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400&family=Ribeye+Marrow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@500&display=swap");
body {
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
  text-align: center;
  letter-spacing: 0.03em;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  color: #343333;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}

a img {
  border: none;
}

a {
  color: #754c24;
  transition: 0.3s;
}

a:hover {
  text-decoration: underline;
  color: #d17e00;
}

.line {
  color: #06C755;
  font-weight: bold;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

* html .clearfix {
  zoom: 1;
}

/* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
}

/* IE7 */
li {
  list-style: none;
}

i {
  padding: 0 5px;
}

.tablet {
  display: none;
}

.sp {
  display: none;
}

.ssp {
  display: none;
}

img {
  max-width: 100%;
}

@media only screen and (max-width: 1024px) {
  img {
    border: 0;
    max-width: 100%;
    height: auto;
  }

  .pc {
    display: none;
  }

  .tablet {
    display: block;
  }

  .sp {
    display: block;
  }

  .ssp {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .tablet {
    display: none;
  }

  .ssp {
    display: block;
  }
}
/*PC改行加える*/
.br-pc {
  display: block;
}

/*PC改行無効*/
.br_sp {
  display: none;
}

.br_ssp {
  display: none;
}

@media only screen and (max-width: 1024px) {
  /*SP改行無効*/
  .br-pc {
    display: none;
  }

  /*SP改行加える*/
  .br_sp {
    display: block;
  }

  .br_ssp {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  /*SP改行無効*/
  .br-pc {
    display: none;
  }

  /*SP改行加える*/
  .br_sp {
    display: block;
  }

  .br_ssp {
    display: block;
  }
}
section:after, article:after, dl:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/************************************

wrapper & common

************************************/
.wrapper {
  max-width: 1080px;
  margin: auto;
  width: 90%;
}
.wrapper:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
/*ズームイン表示*/
.list-mv01 {
  transform: scale(0, 0);
  -webkit-transform: scale(0, 0);
}

.mv01 {
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
}

/*フェードイン表示*/
.list-mv02 {
  opacity: 0;
}

.list-mv02:not(:target) {
  opacity: 1\9 ;
  /*IE9対策*/
}

.mv02 {
  opacity: 1;
}

/*回転*/
.list-mv03 {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.mv03 {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

/*3D回転*/
.list-mv04 {
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
}

.mv04 {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
}

/*上移動*/
.list-mv05 {
  transform: translate(0, 40px);
  -webkit-transform: translate(0, 40px);
}

.mv05 {
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}

/*移動＋フェードイン表示*/
.list-mv07 {
  opacity: 0;
  transform: translate(0, 30px);
  -webkit-transform: translate(0, 30px);
}

.mv07 {
  opacity: 1;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}

@media print {
  .list-mv02, .list-mv07 {
    opacity: 1;
  }
}
/************************************

HEADER

************************************/
/*** div id="header"でないと不可 ***/
@media print {
  #header {
    position: relative;
  }
}

#header {
  width: 100%;
  padding: 0;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px solid #f2f2f2;
  z-index: 999;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

#header a {
  color: #343333;
  text-decoration: none;
}

#header a:hover {
  color: #343333;
  text-decoration: none;
  border-bottom: 2px solid #ede39b;
}

/*** topのみ 
#top #header{
position:absolute;
top:700px;
z-index:100;
transition:0;
}***/
/*** fixed version 
#top #header.fixed {
position:fixed !important;
top:0;
width:100%;
z-index: 9999;
background:#fff;
border-bottom:1px solid #f2f2f2;

}***/
@media only screen and (max-width: 1024px) {
  #header {
    width: 100%;
    padding: 15px 0;
    top: 0;
    height: auto;
    position: relative !important;
    background: #fff;
    border-bottom: 1px solid #f2f2f2;
    z-index: 1;
  }

  /*** topのみ ***/
  #top #header {
    position: relative;
    top: 0;
  }

  /*** fixed version ***/
  #top #header.fixed {
    position: relative !important;
    top: 0;
    width: 100%;
    z-index: 8888;
    background: #fff;
    border-bottom: 1px solid #f2f2f2;
  }
}
#header .wrapper {
  max-width: 94%;
  width: 94%;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

/*** logo ***/
#header h1 a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-image: url(../images/head_logo.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  height: 0;
  width: 180px;
  padding-top: 25.55%;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #header h1 a {
    background-image: url(../images/head_logo@2x.png);
  }
}
@media only screen and (max-width: 1140px) {
  #header h1 a {
    width: 150px;
  }
}
#header h1 a:hover {
  border: none;
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

/*** head_text ***/
#head_text {
  width: calc(100% - 180px - 20px);
  text-align: left;
  margin: 0 0 0px 20px;
  line-height: 100%;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 1140px) {
  #head_text {
    width: calc(100% - 150px - 20px);
  }
}
@media only screen and (max-width: 1024px) {
  #head_text {
    display: none;
  }
}

/*** menu ***/
#header nav {
  width: calc(100% - 250px - 10px);
  text-align: left;
  padding-top: 0px;
  margin: 0 10px 0 0;
}

#head_text .copy {
  font-size: 75%;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1140px) {
  #head_text .copy {
    font-size: 10px;
  }
}
#head_text .copy strong {
  background: linear-gradient(transparent 0%, #F7E58E 0%);
  display: inline-block;
  font-weight: bold;
}

#header nav ul {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

#header nav li {
  font-size: 90%;
  padding: 0 15px 0 0;
}
@media only screen and (max-width: 1140px) {
  #header nav li {
    padding: 0 10px 0 0;
    font-size: 1.3vw;
  }
}

@media only screen and (max-width: 1024px) {
  #header nav {
    display: none;
  }
}
/*** TEL & FB ***/
#header #head_tel {
  width: 250px;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

#header #head_tel a:hover {
  border: none;
  text-decoration: none;
  color: #bb9f00;
}

#header .tel {
  width: 140px;
  text-align: left;
  padding-top: 0px;
  font-size: 15px;
}

#header .mail {
  width: 23px;
  margin-top: 0px;
  padding-left: 0px;
}

#header .fb {
  width: 20px;
  margin: 0px 4px;
  padding-left: 0px;
}

#header #head_tel .contact {
  width: 100%;
}

#header #head_tel .contact a {
  background: #343333;
  color: #fff;
  font-size: 80%;
  padding: 7px 0;
  text-align: center;
  display: block;
  margin-top: 10px;
  position: relative;
}

#header #head_tel .contact a:before {
  position: absolute;
  top: 8px;
  left: 7px;
  color: #fff;
  font: 14px "FontAwesome";
  content: "\f07a";
}

#header #head_tel .contact a:hover {
  background: #bb9f00;
}

@media only screen and (max-width: 1024px) {
  #header #head_tel {
    display: none;
  }
}
/************************************

FOOTER

************************************/
footer {
  background: url(../images/back_beige.jpg);
  padding: 60px 0;
  text-align: left;
  transition: 3s;
}

footer a {
  color: #343333;
  text-decoration: none;
}

footer a:hover {
  color: #343333;
  text-decoration: none;
  border-bottom: 2px solid #ede39b;
}

footer .wrapper {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

footer h2 {
  width: 100%;
}

footer h2 a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-image: url(../images/foot_logo.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  height: 48px;
  width: 190px;
  margin: 0 0 20px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  footer h2 a {
    background-image: url(../images/foot_logo@2x.png);
  }
}
footer h2 a:hover {
  border: none;
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}
@media only screen and (max-width: 1024px) {
  footer h2 a {
    margin: 0 auto 30px;
  }
}

footer nav {
  width: 60%;
}
@media only screen and (max-width: 1024px) {
  footer nav {
    width: 100%;
    margin: 0 auto 20px auto;
    text-align: center;
  }
}

/*** foot menu ***/
footer nav ul {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 90%;
}
@media only screen and (max-width: 1024px) {
  footer nav ul {
    font-size: 80%;
    margin: 0 auto;
    justify-content: center;
  }
}
footer nav ul li {
  margin: 0 20px 5px 0;
  position: relative;
  padding-left: 12px;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  footer nav ul li {
    text-align: left;
    margin: 0 15px 5px 0;
  }
}
footer nav ul li::before {
  position: absolute;
  top: 4px;
  left: 0px;
  color: #bb9f00;
  font: 12px "FontAwesome";
  content: "\f105";
}
footer nav ul.other {
  width: 100%;
  font-size: 90%;
  margin: 15px 0;
}
@media only screen and (max-width: 1024px) {
  footer nav ul.other {
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 80%;
  }
}

footer nav ul.other li {
  padding-left: 0px;
}
footer nav ul.other li:before {
  position: absolute;
  content: "";
}
@media only screen and (max-width: 1024px) {
  footer nav ul.other li span.sp_no {
    display: none;
  }
}
footer nav ul.other li img {
  width: 16px;
  margin: 0 5px 0 4px;
}
@media only screen and (max-width: 1024px) {
  footer nav ul.other li img {
    width: 14px;
  }
}

.qr {
  display: inline-block;
}
@media only screen and (max-width: 600px) {
  .qr {
    padding-bottom: 20px;
  }
}
.qr img {
  width: 200px;
}
@media only screen and (max-width: 600px) {
  .qr img {
    width: 150px;
  }
}

footer nav p {
  font-size: 80%;
  margin: 0 0 15px 0;
}
@media only screen and (max-width: 1024px) {
  footer nav p {
    font-size: 80%;
    margin: 0 0 15px 0px;
  }
}

/**** footer info ***/
footer .info {
  width: 35%;
  padding: 0px 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  footer .info {
    width: 100%;
    margin: 0 auto;
    padding: 0px 0;
    text-align: center;
  }
}

footer .info .list {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1024px) {
  footer .info .list {
    margin: 0 0 20px;
  }
}
footer .info .list article {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  footer .info .list article {
    width: 48%;
    margin: 0 1%;
    text-align: left;
  }
}
@media only screen and (max-width: 600px) {
  footer .info .list article {
    width: 100%;
    margin: 0 0 15px;
    text-align: center;
  }
}

footer .info h3 {
  font-size: 100%;
  margin: 0 0 0;
  font-weight: bold;
}
@media only screen and (max-width: 1024px) {
  footer .info h3 {
    font-size: 90%;
  }
}

footer .produce {
  font-size: 80%;
  margin: 0 0 15px 0;
}
@media only screen and (max-width: 1024px) {
  footer .produce {
    font-size: 75%;
  }
}

footer .info p {
  font-size: 85%;
  text-align: left;
  margin-bottom: 15px;
}
@media only screen and (max-width: 1024px) {
  footer .info p {
    font-size: 80%;
  }
}
@media only screen and (max-width: 600px) {
  footer .info p {
    text-align: center;
  }
}

/******** PAGEUP ********/
#pageup a {
  width: 47px;
  background: #343333;
  color: #fff;
  position: fixed;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 20px;
  line-height: 100%;
  padding: 10px 0;
  z-index: 9999;
  display: block;
}

#pageup a:hover {
  border: none;
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@media only screen and (max-width: 1024px) {
  #pageup a {
    width: 36px;
    background: #fff;
    color: #ede39b;
    /*border:1px solid #333;*/
    position: fixed;
    right: 5px;
    bottom: 5px;
    text-align: center;
    font-size: 24px;
    line-height: 100%;
    padding: 5px 0 6px 0;
    /*全体角丸*/
    border-radius: 18px;
    /* CSS3草案 */
    -webkit-border-radius: 18px;
    /* Safari,Google Chrome用 */
    -moz-border-radius: 18px;
    /* Firefox用 */
  }

  #pageup a:hover {
    /*border:1px solid #333;*/
  }
}
/**********************************

スマホtoiawase

**********************************/
@media only screen and (max-width: 1024px) {
  #sp-toi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    border-top: 1px solid #ccc;
    background: #ede39b;
  }

  #sp-toi a {
    color: #343333;
    text-decoration: none;
  }

  #sp-toi ul {
    margin: 0px 45px 0 0px;
    text-align: left;
    height: 45px;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
  }

  #sp-toi ul li {
    font-size: 90%;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 600px) {
  #sp-toi ul li {
    font-size: 3.7vw;
  }
}
@media only screen and (max-width: 1024px) {
  #sp-toi ul li i {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  #sp-toi ul li a {
    padding: 0 7px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 600px) {
  #sp-toi ul li a {
    padding: 0 2vw;
  }
}
@media only screen and (max-width: 1024px) {
  #sp-toi ul li img {
    vertical-align: middle;
    width: 22px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 600px) {
  #sp-toi ul li img {
    width: 26px;
  }
}
@media only screen and (max-width: 1024px) {
  #sp-toi ul li.shop {
    background-color: #333;
    height: 45px;
    line-height: 45px;
  }
  #sp-toi ul li.shop a {
    color: #fff;
  }
}
/**********************************

スマホメニュー用

**********************************/
.drawer--top.drawer-open .drawer-nav {
  top: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer--left.drawer-open .drawer-nav,
.drawer--left .drawer-hamburger,
.drawer--left.drawer-open .drawer-navbar .drawer-hamburger {
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer--right.drawer-open .drawer-nav,
.drawer--right .drawer-hamburger,
.drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer-hamburger {
  position: fixed;
  z-index: 9999;
  top: 0;
  display: block;
  box-sizing: content-box;
  width: 45px;
  height: 45px;
  color: #fff;
  padding: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: #333;
}

.drawer-hamburger:hover {
  cursor: pointer;
  background-color: transparent;
  background-color: #333;
}

/**3本線**/
.drawer-hamburger-icon,
.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  background-color: #333;
}

.drawer-hamburger #menuicon {
  margin: 10px 0;
  font-size: 18px;
}

.drawer-nav {
  text-align: left;
}

.drawer-nav .container {
  padding: 40px 8%;
}

.drawer-nav h2 a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-image: url(../images/foot_logo@2x.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  height: 48px;
  width: 190px;
  margin: 0 auto 20px;
}

.drawer-nav a {
  text-decoration: none;
  color: #000;
  font-size: 100%;
}

.drawer-nav ul li {
  font-weight: normal;
  border-bottom: 1px solid #eee;
  font-size: 15px;
}

.drawer-nav ul li a {
  padding: 10px 12px 10px 0;
  display: block;
  position: relative;
}
.drawer-nav ul li a::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  color: #bb9f00;
  font-family: "FontAwesome";
  content: "\f105";
}

.drawer-nav ul li img {
  width: 15px;
  vertical-align: middle;
  margin: 0 4px;
}

.drawer-nav ul li ul {
  padding: 3%;
}

.drawer-nav ul li ul li {
  padding: 2px 8px 2px 8px;
  font-size: 90%;
  display: block;
  position: relative;
  font-weight: normal;
}

.drawer-nav ul li ul li:before {
  position: absolute;
  top: 13px;
  left: 0px;
  color: #595757;
  font: 12px "FontAwesome";
  content: "\f105";
}

/************************************

SECOND PAGE COMMON

************************************/
#second {
  padding-top: 100px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 1024px) {
  #second {
    padding-top: 0;
  }
}
#second #mainimage {
  position: relative;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  height: 240px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1024px) {
  #second #mainimage {
    height: 150px;
  }
}
#second #mainimage .pagetitle {
  background-color: #fff;
  padding: 20px 80px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
@media only screen and (max-width: 1024px) {
  #second #mainimage .pagetitle {
    padding: 20px 40px 0;
  }
}
#second #mainimage .pagetitle .title {
  margin-bottom: 0;
}

/************************************

ぱんくずリスト(SP)

************************************/
nav#pan-sp {
  display: block;
  text-align: left;
}

nav#pan-sp .wrapper {
  width: 90%;
  padding: 5px 0;
}

nav#pan-sp li {
  font-size: 70%;
  display: inline-block;
  padding: 0 18px 0 0;
  position: relative;
}

nav#pan-sp li:before {
  position: absolute;
  top: 3px;
  right: 3px;
  color: #595757;
  font: 11px "FontAwesome";
  content: "\f105";
}

nav#pan-sp li:last-child:before {
  content: "";
}

nav#pan-sp li a {
  color: #343333;
  text-decoration: none;
}

nav#pan-sp li a:hover {
  color: #343333;
  text-decoration: underline;
}

/************************************

ぱんくずリスト(PC)

************************************/
nav#pan {
  text-align: left;
  padding: 20px 0;
  font-size: 80%;
}

nav#pan .wrapper {
  max-width: 1040px;
}

nav#pan li {
  display: inline-block;
  padding: 0 30px 0 0;
  position: relative;
}

nav#pan li:before {
  position: absolute;
  top: 5px;
  right: 15px;
  color: #595757;
  font: 12px "FontAwesome";
  content: "\f105";
}

nav#pan li:last-child:before {
  content: "";
}

nav#pan li a {
  color: #343333;
  text-decoration: none;
}

nav#pan li a:hover {
  color: #343333;
  text-decoration: underline;
}

@media only screen and (max-width: 1024px) {
  nav#pan {
    display: none;
  }
}
/************************************

TITLE

************************************/
.title {
  margin: 0 auto 40px auto;
}
@media only screen and (max-width: 1024px) {
  .title {
    margin: 0 auto 30px auto;
  }
}

.title h2 {
  font-size: 120%;
}
@media only screen and (max-width: 1024px) {
  .title h2 {
    font-size: 100%;
  }
}

.title h2 a {
  color: #343333;
  text-decoration: none;
}

.title h2 a:hover {
  color: #dfcd4d;
}

.title h2 span {
  font-family: "Dancing Script", cursive;
  font-weight: 400;
  font-size: 40px;
  display: block;
  margin: 0 auto 5px;
  padding: 0 20px;
  border-bottom: 3px dotted #bb9f00;
}
@media only screen and (max-width: 1024px) {
  .title h2 span {
    font-size: 28px;
  }
}

/************************************

BT

************************************/
.bt a {
  display: block;
  background: #bb9f00;
  width: 340px;
  margin: 0 auto;
  padding: 13px;
  font-size: 100%;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  position: relative;
  /*全体角丸*/
  border-radius: 23px;
  /* CSS3草案 */
  -webkit-border-radius: 23px;
  /* Safari,Google Chrome用 */
  -moz-border-radius: 23px;
  /* Firefox用 */
  transition: 0.5s;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  .bt a {
    width: 100%;
  }
}

.bt a:before {
  position: absolute;
  top: 18px;
  left: 20px;
  color: #fff;
  font: 14px "FontAwesome";
  content: "\f105";
}

.bt a:hover {
  background-color: #dfcd4d;
}

/************************************

PAGENAVI

************************************/
#pagenavi {
  margin: 60px auto 0 auto;
  padding: 0px 0;
}
@media only screen and (max-width: 600px) {
  #pagenavi {
    margin: 40px 5% 0 5%;
    padding: 0px 0;
  }
}

#pagenavi ul {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

#pagenavi li {
  width: 31.3%;
  margin: 0 1%;
}
@media only screen and (max-width: 600px) {
  #pagenavi li {
    width: 100%;
    display: block;
    margin: 0 0 5px 0;
  }
}

#pagenavi li a {
  border: 1px dotted #343333;
  padding: 30px 0;
  text-decoration: none;
  color: #343333;
  font-size: 90%;
  font-weight: 500;
  position: relative;
  display: block;
}
@media only screen and (max-width: 1024px) {
  #pagenavi li a {
    padding: 20px 0;
    font-size: 85%;
  }
}
@media only screen and (max-width: 600px) {
  #pagenavi li a {
    border: 1px dotted #343333;
    padding: 15px 0;
    text-decoration: none;
    color: #343333;
    font-weight: 500;
    position: relative;
    display: block;
  }
}

#pagenavi li a:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 14px;
  margin: auto;
  color: #bb9f00;
  width: 14px;
  height: 14px;
  font: 14px "FontAwesome";
  content: "\f105";
}

#pagenavi li a:hover {
  color: #bb9f00;
}

/************************************

BASE TABLE

************************************/
table.basetable {
  border-top: 2px solid #ede39b;
  border-left: 2px solid #ede39b;
  border-right: 2px solid #ede39b;
  border-bottom: 1px solid #ede39b;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-size: 90%;
  line-height: 180%;
  box-sizing: border-box;
}

table.basetable th {
  border-right: 1px solid #ede39b;
  border-bottom: 1px solid #ede39b;
  padding: 12px 30px;
  background: #fbf8e6;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  table.basetable th {
    padding: 12px;
  }
}
@media only screen and (max-width: 600px) {
  table.basetable th {
    display: block;
    border-right: none;
    padding: 3% 5%;
    width: 100%;
  }
}

table.basetable td {
  border-bottom: 1px solid #ede39b;
  padding: 12px 30px;
}
@media only screen and (max-width: 600px) {
  table.basetable td {
    display: block;
    padding: 3% 5%;
  }
}

/************************************

DOT LIST

************************************/
/*** 一番上の枠 ***/
/* 3つ */
/* 2つ */
/* 1つ */
/*** ２行目以降の枠 ***/
/* 3つ */
/* 2つ */
/* 1つ */
/*** 一番下の枠 ***/
/* 3つ */
/* 2つ */
/* 1つ */
.dotlist .list {
  display: block;
  /* before Android4.3 iOS6:Safari */
  display: -ms-flexbox;
  /*IE10*/
  display: -webkit-flex;
  /* Safari */
  display: flex;
  -webkit-flex-wrap: wrap;
  /* Safari */
  flex-wrap: wrap;
  border-top: 1px dotted #ccc;
  border-left: 1px dotted #ccc;
}

.dotlist .list article {
  width: 33.3%;
  padding: 25px 3%;
  position: relative;
  border-right: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  box-sizing: border-box;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .dotlist .list article {
    padding: 20px 3%;
  }
}
@media only screen and (max-width: 600px) {
  .dotlist .list article {
    width: 100%;
    padding: 25px 7%;
  }
}

.dotlist .list article .image {
  margin: 0px 0;
}

/* Flashing */
.dotlist .list article .image a:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

.dotlist .list article h4 {
  margin: 10px 0 5px;
  font-size: 120%;
  color: #bb9f00;
  font-weight: 500;
  display: inline-block;
  text-align: left;
}
@media only screen and (max-width: 1024px) {
  .dotlist .list article h4 {
    font-size: 110%;
  }
}
@media only screen and (max-width: 600px) {
  .dotlist .list article h4 {
    font-size: 100%;
  }
}

.dotlist .list article h4 a {
  color: #bb9f00;
  text-decoration: none;
}

.dotlist .list article h4 a:hover {
  color: #ede39b;
  text-decoration: none;
}

.dotlist .list article p {
  margin: 0px 0;
  text-align: left;
  font-size: 90%;
  line-height: 1.6;
}
@media only screen and (max-width: 1024px) {
  .dotlist .list article p {
    font-size: 80%;
    line-height: 1.6;
  }
}
.dotlist .list article p small {
  display: inline-block;
}

/************************************

RAF

************************************/
#raf-pc {
  width: 1500px;
  margin: 0 auto;
}

/*! このcssはsass（sass/同名の.scssファイル）からコンパイルされています。修正時はこのcssを直接いじらないようにお願いします */
a.booking-timelink {
  display: block;
  pointer-events: none;
  text-decoration: none;
}